<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--广告-->
      <div class="ad">
        <!--广告容器-->
        <div class="ad_container">
          <!--分栏广告-->
          <BannerAds></BannerAds>
          <!--文字广告-->
          <TextAds class="mt22"></TextAds>
        </div>
      </div>
      <!--面包屑-->
      <div class="bread_crumbs">
        <div class="bread_content">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
            <el-breadcrumb-item>皮肤</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <!--内容-->
      <div class="content">
        <!--过滤器-->
        <div class="filters">
          <!--能力-->
          <div class="filter">
            <div class="title">能力:</div>
            <div class="btn">
              <div class="options">
                <el-checkbox-group  size="small">
                  <el-checkbox-button label="全部" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                  <el-checkbox-button label="占位文本" />
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <!--数据数据-->
        <div class="skin_data">
          <div class="item" v-for="(item, index) in article_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="text">
              <p class="title" @click="jump_page('skin_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BannerAds from "../../components/BannerAds";
import TextAds from "../../components/TextAds";
import {get_article_list} from "../../api/api";
import API_CONFIG from "../../../vue.config";
import {jump_page} from "../../tool/tools";

export default {
  name: "Skin",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  mounted() {
    this.obtain_article();
  },
  data(){
    return {
      article_list: []
    }
  },
  methods: {
    jump_page,
    obtain_article(){
      get_article_list({type: "3"}).then(response => {
        if(response.Code === 1){
          this.article_list = response.Data;
          for(let i=0; i <= this.article_list.length; i++){
            this.article_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.article_list[i].cover_info.cover_image;
          }
        }
      });
    }
  },
  components: {
    Header,
    Footer,
    BannerAds,
    TextAds
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.bread_crumbs{
  height: 36px;
  background: #2e2f31;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content{
  width: 1050px;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
  font-size: 18px;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
}

.bread_crumbs > .bread_content > .el-breadcrumb >>> .el-breadcrumb__inner{
  color: #FFFFFF;
}

.filters {
  margin-bottom: 20px;
}

/* 过滤器 */
.filter:first-child{
  margin-top: 0;
}
.filter {
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 15px 0 10px;
}

.filter > .title{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}


.filter > .btn{
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}
.filter > .btn > .options {
  width: 980px;
  height: auto;
  overflow: hidden;
}

.skin_data{
  padding: 0 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.skin_data > .item{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 12px 10px 12px;
  background: #f7f8fa;
}

.skin_data > .item > img{
  width: 230px;
  height: 120px;
  margin-right: 13px;
}

.skin_data > .item > .text{
  padding: 13px;
  flex: 1;
  line-height: 17px;
  font-size: 12px;
}

.skin_data > .item > .text > p{
  width: 100%;
  height: 17px;
  line-height: 17px;
}

.skin_data > .item > .text > .title{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  color: #35b260;
  margin-bottom: 10px;
}

.skin_data > .item > .text > .title:hover{
  cursor: pointer;
  text-decoration: underline;
}

.skin_data > .item > .text > .version{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  color: #959595;
  margin-bottom: 10px;
}


</style>